// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contents-detail-jsx": () => import("./../../../src/pages/contents_detail.jsx" /* webpackChunkName: "component---src-pages-contents-detail-jsx" */),
  "component---src-pages-contents-jsx": () => import("./../../../src/pages/contents.jsx" /* webpackChunkName: "component---src-pages-contents-jsx" */),
  "component---src-pages-dx-hr-development-index-jsx": () => import("./../../../src/pages/dx-hr-development/index.jsx" /* webpackChunkName: "component---src-pages-dx-hr-development-index-jsx" */),
  "component---src-pages-dx-hr-development-sent-jsx": () => import("./../../../src/pages/dx-hr-development/sent.jsx" /* webpackChunkName: "component---src-pages-dx-hr-development-sent-jsx" */),
  "component---src-pages-dx-system-development-index-jsx": () => import("./../../../src/pages/dx-system-development/index.jsx" /* webpackChunkName: "component---src-pages-dx-system-development-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-works-dx-human-introduce-jsx": () => import("./../../../src/pages/works/dx-humanIntroduce.jsx" /* webpackChunkName: "component---src-pages-works-dx-human-introduce-jsx" */),
  "component---src-pages-works-dx-solution-jsx": () => import("./../../../src/pages/works/dx-solution.jsx" /* webpackChunkName: "component---src-pages-works-dx-solution-jsx" */),
  "component---src-pages-works-index-jsx": () => import("./../../../src/pages/works/index.jsx" /* webpackChunkName: "component---src-pages-works-index-jsx" */),
  "component---src-pages-works-valve-maintenance-system-jsx": () => import("./../../../src/pages/works/valve-maintenance-system.jsx" /* webpackChunkName: "component---src-pages-works-valve-maintenance-system-jsx" */),
  "component---src-pages-works-work-product-development-jsx": () => import("./../../../src/pages/works/work-product-development.jsx" /* webpackChunkName: "component---src-pages-works-work-product-development-jsx" */)
}

